var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('base-inner-banner',{attrs:{"banner-image":_vm.pageBanner,"is-custom-banner":true,"banner":_vm.banner}}),_c('div',{staticClass:"content-wrapper "},[_c('div',{staticClass:"container"},[_c('base-page-head',{attrs:{"page-head":_vm.pagehead,"show-user-level":true,"userData":_vm.userData}}),_c('div',{staticClass:"page-contents"},[_c('div',{staticClass:"sidenav-wrapper"},[_c('aside',{staticClass:"left-col"},[_c('account-nav')],1),_c('div',{staticClass:"right-col"},[_c('div',{staticClass:"ideaDetail"},[_c('div',{staticClass:"ideaDetail__head"},[(
                                        [
                                            'rejected',
                                            'dismissed',
                                            'resubmit',
                                            'attempted' ].includes(_vm.idea.state) &&
                                            _vm.idea[
                                                ((_vm.idea.state.replace(
                                                    'ed',
                                                    ''
                                                )) + "_feedback")
                                            ]
                                    )?_c('div',{staticClass:"ideaDetail__head--status"},[_c('div',{domProps:{"innerHTML":_vm._s(
                                            _vm.idea[
                                                ((_vm.idea.state.replace(
                                                    'ed',
                                                    ''
                                                )) + "_feedback")
                                            ]
                                        )}})]):_vm._e(),_c('div',{staticClass:"ideaDetail__head--date"},[_c('base-idea-date',{attrs:{"created":_vm.idea.created,"idea-status":_vm.idea.state
                                                ? _vm.$t(
                                                      _vm.ideasStatusList[
                                                          _vm.idea.state
                                                      ].text
                                                  )
                                                : '',"status-class":_vm.idea.state}})],1),_c('div',{staticClass:"ideaDetail__head--edit"},[(_vm.idea.state === 'resubmit')?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                            name: 'resubmit-idea',
                                            params: {
                                                uuid: _vm.idea.uuid,
                                            },
                                        }}},[_vm._v(" "+_vm._s(_vm.$t('Resubmit'))+" ")]):(
                                            [
                                                'submitted',
                                                'approved',
                                                'planned',
                                                'implemented' ].includes(_vm.idea.state)
                                        )?_c('router-link',{staticClass:"btn btn-primary",attrs:{"to":{
                                            name: 'edit-idea',
                                            params: {
                                                uuid: this.idea.uuid,
                                            },
                                        }}},[_vm._v(" "+_vm._s(_vm.$t('Edit'))+" ")]):_vm._e()],1)]),_c('div',{staticClass:"ideaDetail__gallery"},[(this.medias.length)?_c('gallery-profile',{attrs:{"medias":this.medias}}):_vm._e()],1),_c('div',{staticClass:"ideaDetail__title-descripiton"},[_c('h2',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.ideaObject.title)+" ")]),_c('p',{domProps:{"innerHTML":_vm._s(_vm.ideaObject.description)}})]),(_vm.ideaObject && _vm.ideaObject.form_values)?_c('div',{staticClass:"ideaDetail__title-descripiton"},_vm._l((_vm.ideaObject.form_values),function(value,key){return _c('div',{key:key},[(_vm.formatData(value.value))?_c('div',[_c('h2',[_vm._v(" "+_vm._s(value[ ("label_" + (_vm.$i18n.locale)) ])+" ")]),_c('p',[_vm._v(_vm._s(_vm.formatData(value.value)))])]):_vm._e()])}),0):_vm._e(),_c('timeline-description')],1)])])])],1)]),_c('b-modal',{ref:"editIdea",attrs:{"id":"editIdea","hide-footer":""},on:{"hidden":function($event){return _vm.closeModal()}}},[_c('div',{staticClass:"editIdea"},[(
                    _vm.dashboardSettings &&
                        _vm.dashboardSettings.dashboard_settings
                )?_c('div',{staticClass:"editIdea__title",domProps:{"innerHTML":_vm._s(_vm.getPopupText('title'))}}):_vm._e(),_c('div',{staticClass:"editIdea__image"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/images/submitidea-img.png"),"alt":""}}),_c('span',[_c('img',{attrs:{"src":require("@/assets/images/svg/pattern.svg"),"alt":""}})])]),_c('div',{staticClass:"inline-buttons"},[_c('a',{staticClass:"btn btn-primary",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){return _vm.$refs.editIdea.hide()}}},[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }