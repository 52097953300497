<template>
    <div>
        <!-- Banner -->
        <base-inner-banner
            :banner-image="pageBanner"
            :is-custom-banner="true"
            :banner="banner"
        ></base-inner-banner>
        <!-- Banner -->

        <div class="content-wrapper ">
            <div class="container">
                <!-- Page Head -->
                <base-page-head
                    :page-head="pagehead"
                    :show-user-level="true"
                    :userData="userData"
                ></base-page-head>
                <!-- Page Head -->

                <!-- Page Contents -->

                <div class="page-contents">
                    <div class="sidenav-wrapper">
                        <aside class="left-col">
                            <account-nav></account-nav>
                        </aside>
                        <div class="right-col">
                            <div class="ideaDetail">
                                <div class="ideaDetail__head">
                                    <div
                                        v-if="
                                            [
                                                'rejected',
                                                'dismissed',
                                                'resubmit',
                                                'attempted',
                                            ].includes(idea.state) &&
                                                idea[
                                                    `${idea.state.replace(
                                                        'ed',
                                                        '',
                                                    )}_feedback`
                                                ]
                                        "
                                        class="ideaDetail__head--status"
                                    >
                                        <div
                                            v-html="
                                                idea[
                                                    `${idea.state.replace(
                                                        'ed',
                                                        '',
                                                    )}_feedback`
                                                ]
                                            "
                                        />
                                    </div>
                                    <div class="ideaDetail__head--date">
                                        <base-idea-date
                                            :created="idea.created"
                                            :idea-status="
                                                idea.state
                                                    ? $t(
                                                          ideasStatusList[
                                                              idea.state
                                                          ].text,
                                                      )
                                                    : ''
                                            "
                                            :status-class="idea.state"
                                        ></base-idea-date>
                                    </div>
                                    <!-- <share-icons :idea="idea" /> -->
                                    <div class="ideaDetail__head--edit">
                                        <router-link
                                            v-if="idea.state === 'resubmit'"
                                            class="btn btn-primary"
                                            :to="{
                                                name: 'resubmit-idea',
                                                params: {
                                                    uuid: idea.uuid,
                                                },
                                            }"
                                        >
                                            {{ $t('Resubmit') }}
                                        </router-link>
                                        <router-link
                                            v-else-if="
                                                [
                                                    'submitted',
                                                    'approved',
                                                    'planned',
                                                    'implemented',
                                                ].includes(idea.state)
                                            "
                                            class="btn btn-primary"
                                            :to="{
                                                name: 'edit-idea',
                                                params: {
                                                    uuid: this.idea.uuid,
                                                },
                                            }"
                                        >
                                            {{ $t('Edit') }}
                                        </router-link>
                                    </div>
                                </div>
                                <!-- <div class="ideaDetail__description">
                                    <h1>{{ idea.title }}</h1>
                                </div> -->
                                <!-- Gallery -->
                                <div class="ideaDetail__gallery">
                                    <gallery-profile
                                        v-if="this.medias.length"
                                        :medias="this.medias"
                                    />
                                </div>
                                <!-- Gallery -->

                                <!-- Title and Description -->
                                <div class="ideaDetail__title-descripiton">
                                    <h2 class="title">
                                        {{ ideaObject.title }}
                                    </h2>
                                    <p v-html="ideaObject.description"></p>
                                </div>
                                <!-- Title and Description -->

                                <!-- Dynamic Form Fields -->
                                <div
                                    class="ideaDetail__title-descripiton"
                                    v-if="ideaObject && ideaObject.form_values"
                                >
                                    <div
                                        v-for="(value,
                                        key) in ideaObject.form_values"
                                        :key="key"
                                    >
                                        <div v-if="formatData(value.value)">
                                            <h2>
                                                {{
                                                    value[
                                                        `label_${$i18n.locale}`
                                                    ]
                                                }}
                                            </h2>
                                            <p>{{ formatData(value.value) }}</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Dynamic Form Fields -->
                                <!-- Ideas Reviews -->
                                <!-- <div class="ideaDetail__reviews">
                                    <base-reviews
                                        v-if="idea.id"
                                        :object="idea"
                                        @social="social"
                                    ></base-reviews>
                                </div> -->
                                <!-- Ideas Reviews -->

                                <!-- Post Comments -->
                                <!-- <base-post-comments
                                    :object="idea"
                                    @click="addComment"
                                /> -->
                                <!-- Post Comments -->

                                <!-- Timeline and description -->
                                <timeline-description></timeline-description>
                                <!-- Timeline and description -->

                                <!-- Reviews Brief -->
                                <!-- <div class="ideaDetail__reviews--brief">
                                    <reviews
                                        ref="refReviews"
                                        @addComment="addComment"
                                        @likeComment="likeComment"
                                    />
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- editIdea popup -->
        <b-modal
            id="editIdea"
            ref="editIdea"
            hide-footer
            @hidden="closeModal()"
        >
            <div class="editIdea">
                <div
                    v-if="
                        dashboardSettings &&
                            dashboardSettings.dashboard_settings
                    "
                    class="editIdea__title"
                    v-html="getPopupText('title')"
                ></div>
                <div class="editIdea__image">
                    <img
                        class="img-fluid"
                        src="@/assets/images/submitidea-img.png"
                        alt=""
                    />
                    <span
                        ><img src="@/assets/images/svg/pattern.svg" alt=""
                    /></span>
                </div>
                <div class="inline-buttons">
                    <a
                        class="btn btn-primary"
                        href="javascript:void(0)"
                        @click="$refs.editIdea.hide()"
                    >
                        {{ $t('Close') }}
                    </a>
                </div>
            </div>
        </b-modal>
        <!-- editIdea popup -->
    </div>
</template>

<script>
import imgMedium from '@/assets/images/banners/ideas-submit-banner-1200x900.png'
import imgLarge from '@/assets/images/banners/ideas-submit-banner-1600x900.png'
import imgBig from '@/assets/images/banners/ideas-submit-banner-1920x600.png'
import { IdeasHelper } from '@/common/crud-helpers/ideas'
import GalleryProfile from '@/modules/ideas/components/details/GalleryProfile'
// import Reviews from '@/modules/ideas/components/details/Reviews'
// import ShareIcons from '@/modules/ideas/components/details/ShareIcons'
import TimelineDescription from '@/modules/ideas/components/details/TimelineDescription'
import { ideasAddComment } from '@/modules/ideas/mixins/ideasAddComment.js'
import { ideasReviews } from '@/modules/ideas/mixins/ideasReviews.js'
import _ from 'lodash'
import cloneDeep from 'lodash/cloneDeep'
import { mapGetters } from 'vuex'
export default {
    mixins: [ideasReviews, ideasAddComment],
    components: {
        GalleryProfile,
        TimelineDescription,
        // ShareIcons,
        // Reviews,
    },
    data() {
        return {
            pageBanner: {
                imgBig: imgBig,
                imgLarge: imgLarge,
                imgMedium: imgMedium,
                imgAlt: this.$t('My ideas'),
            },
            userData: {},
            gallerySettings: {
                arrows: false,
                dots: true,
                centerMode: false,
                variableWidth: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                focusOnSelect: true,
            },
            pagehead: {
                title: this.$t('My ideas'),
                subtitle: '',
                shortdes: this.$t(
                    'At the core of Zainiac we have the ideas submitted by our Zainers. These ideas are what enable us to remain a leading organization in the region.',
                ),
            },
            medias: [],
            idea: {},
            banner: '',
        }
    },
    created() {
        if (this.$route.params.uuid) {
            IdeasHelper.get(this.$route.params.uuid)
        }
        if (
            this.dashboardSettings &&
            this.dashboardSettings.dashboard_tabs &&
            this.dashboardSettings.dashboard_tabs.length
        ) {
            this.setBannerAndUser()
        }
    },
    mounted() {
        if (
            this.$route?.query?.state &&
            this.dashboardSettings?.dashboard_settings
        ) {
            this.$refs.editIdea.show()
        }
    },
    methods: {
        formatData(value) {
            let fomratedValue = ''
            if (Array.isArray(value) === true) {
                fomratedValue = value
                    .map(item => {
                        return item
                    })
                    .join(', ')
            } else if (typeof value === 'object') {
                fomratedValue = Object.keys(value)
                    .filter(item => value[item])
                    .join(', ')
            } else fomratedValue = value
            return fomratedValue
        },
        setBannerAndUser() {
            let activeTab = this.dashboardSettings.dashboard_tabs.find(tab =>
                tab?.value?.url?.includes('profile-settings'),
            )
            this.banner = activeTab?.value?.banner || ''
            if (this.userAuth.id) {
                this.userData = cloneDeep(this.userAuth)
            }
        },
        closeModal() {
            this.$router.replace({
                name: 'user-idea-details',
                params: {
                    uuid: this.$route.params.uuid,
                },
            })
        },
        getPopupText(type) {
            if (this.$route?.query?.state === 'ideaEdited') {
                return this.dashboardSettings.dashboard_settings[
                    `edited_submitted_${type}_${this.$i18n.locale}`
                ]
            } else if (this.$route?.query?.state === 'imageAdded') {
                return this.dashboardSettings.dashboard_settings[
                    `edited_idea_image_${type}_${this.$i18n.locale}`
                ]
            }
        },
    },
    computed: {
        ...mapGetters([
            'ideaObject',
            'ideasStatusList',
            'userAuth',
            'dashboardSettings',
        ]),
    },
    watch: {
        ideaObject() {
            this.idea = cloneDeep(this.ideaObject)
            let previewImage = {}
            previewImage.file = this.idea.preview_image
            let allMedias = this.idea.previewImage
                ? _.concat([previewImage], this.ideaObject.medias)
                : this.ideaObject.medias
            this.medias = cloneDeep(allMedias)
        },
        dashboardSettings() {
            this.setBannerAndUser()
            if (this.$route?.query?.state) {
                this.$refs.editIdea.show()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.sidenav-wrapper {
    margin-top: rem(95px);
}
/deep/ {
    .content-wrapper {
        .page__head {
            min-height: auto;
        }
    }
}
.editIdea {
    max-width: 720px;
    margin: auto;
    text-align: center;
    &__title {
        /deep/ p {
            font-size: rem(52px);
            font-weight: 900;
            color: #009b74;
            text-align: center;
            margin-bottom: rem(50px);
            text-transform: uppercase;
            @media screen and (max-width: 767px) {
                font-size: rem(38px);
            }
            @media screen and (max-width: 575px) {
                font-size: rem(30px);
            }
        }
    }
    &__image {
        position: relative;
        @media screen and (max-width: 767px) {
            padding: rem(25px) 0;
        }
        span {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            img {
                max-width: 220px;
                transform: rotate(-80deg) translateX(50%);
                @media screen and (max-width: 767px) {
                    max-width: 38%;
                }
            }
        }
    }
    .inline-buttons {
        padding-top: rem(55px);
        a {
            margin-left: rem(20px);
            margin-right: rem(20px);
            padding: 12px 23px;
            font-size: 18px !important;
        }
        .btn-pink {
            font-size: 1.5rem;
        }
        .btn-primary {
            border: solid 2px transparent;
            &:hover {
                background: transparent;
                border-color: var(--primary);
                color: var(--primary);
            }
        }
        @media screen and (max-width: 575px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            a {
                font-size: 16px !important;
                &:not(:last-child) {
                    margin: 0;
                    margin-bottom: rem(15px);
                }
            }
        }
    }
}
.ideaDetail {
    padding-top: rem(150px);
    &__head {
        @include flex(center, space-between);
        @media screen and (max-width: 420px) {
            flex-direction: column;
            align-items: normal;
        }
        &--date {
            /deep/ .idea--date {
                @media screen and (max-width: 420px) {
                    margin-bottom: 2rem;
                }
            }
        }
        &--status {
            margin-bottom: 2rem;
            font-size: 18px;
            padding: 0.5rem;
            width: 100%;
            background-color: var(--primary);
            color: var(--white);
            white-space: pre-wrap;
            div {
                padding: 0.5rem;
            }
        }
        &--edit {
            .btn-primary {
                min-width: 8rem;
            }
            .btn {
                padding: rem(20px) rem(20px);
                line-height: 0.5;
                text-transform: none !important;
                font-size: rem(20px);
                font-weight: 500;
            }
        }
    }
    &__description {
        margin-top: rem(25px);
        p {
            font-size: rem(16px);
            color: #333333;
            line-height: 1.5;
        }
    }
    &__title-descripiton {
        h2 {
            color: var(--primary);
            font-size: rem(18px);
            font-weight: 900;
            margin-bottom: rem(18px);
            text-transform: uppercase;
            &.title {
                font-size: rem(25px) !important;
            }
        }
        p {
            color: #333333;
            line-height: 1.6;
            text-align: justify;
            overflow-wrap: break-word;
            white-space: pre-wrap;
            font-size: rem(16px);
        }
    }
    &__gallery {
        margin-bottom: 1rem;
        margin-top: 2rem;
        /deep/ {
            .ideaGallery {
                padding: 0px rem(35px) 0 rem(35px);
            }
        }
    }
    &__reviews {
        margin-bottom: rem(40px);
        &--brief {
            /deep/ {
                .tabs {
                    &:not(.ideas-tabs) {
                        > div {
                            > .nav-tabs {
                                > .nav-item {
                                    .nav-link {
                                        font-size: rem(20px);
                                        padding: rem(26px) 0;
                                    }
                                }
                            }
                        }
                        > .tab-content {
                            padding: 0 !important;
                        }
                    }
                }
                .comments {
                    padding: 30px;
                    padding-right: 0;
                    .comment__row {
                        padding-right: rem(30px);
                    }
                }
                .scrollWrapper {
                    &.likes {
                        height: 550px;
                    }
                }
                .users {
                    padding: rem(30px);
                    padding-right: 0;
                    padding-bottom: 0;
                    &__item {
                        &:not(:last-child) {
                            padding-right: rem(30px);
                        }
                    }
                }
                @media screen and (max-width: 1199px) {
                    .scrollWrapper {
                        &.likes {
                            height: 450px;
                        }
                    }
                }
                @media screen and (max-width: 1199px) and (min-width: 992px) {
                    .tabs:not(.ideas-tabs) {
                        > div {
                            > .nav-tabs {
                                > .nav-item {
                                    .nav-link {
                                        font-size: rem(16px);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            margin-bottom: 0;
            /deep/ {
                .list-inline {
                    justify-content: center;
                }
            }
        }
    }
    /deep/ {
        .ideaGallery {
            .slick-slider {
                margin: 0 3px;
            }
            .slick-dots {
                padding-top: 1rem;
                position: sticky;
                li {
                    &.slick-active {
                        button::before {
                            color: black;
                            opacity: 1;
                            border: 1px solid #009b74;
                            border-radius: 50%;
                        }
                    }
                    button::before {
                        color: #009b74;
                        opacity: 1;
                    }
                }
            }
            // .slick-slide{
            //     margin:0 3px;
            // }
            // .slick-list{
            //     margin:0 -3px;
            // }
            &__slide {
                vertical-align: top;
                &--image {
                    img,
                    video {
                        transform: scale(1);
                        border: solid 2px #009b74;
                    }
                }
            }
            .slick-current .ideaGallery__slide--image {
                border: 0px;
                img {
                    object-fit: contain;
                }
            }
        }
        .timeline {
            overflow: hidden;
            padding-bottom: rem(70px);
            padding-left: 1px;
            padding-right: 1px;
            &__item {
                width: 155px;
                height: 157px;
                padding-top: 0;
                &:not(:last-child) {
                    &::after {
                        width: 22px;
                        height: 22px;
                        right: -17px;
                        border: 1px solid #4f4f4f;
                    }
                }
                &.completed:last-child:before {
                    transform: translate(-50%, -50%) rotate(182deg);
                }
                &:nth-child(2) {
                    margin-top: -5px;
                }
                &:nth-child(3) {
                    margin-top: -5px;
                }
                &:last-child {
                    margin-bottom: -2px;
                }

                &:before {
                    height: 96%;
                    background-size: contain;
                }
                &--icon {
                    height: 38px;
                    margin-bottom: rem(10px);
                    svg {
                        width: 40px;
                    }
                }
                &--name {
                    font-size: rem(15.82px);
                    min-height: rem(30px);
                    margin-bottom: rem(5px);
                }
                &--date {
                    font-size: rem(14.5px);
                }
                &--shadow {
                    bottom: -42px;
                }
                &:nth-child(4) {
                    background-size: cover;
                }
                &.unactive {
                    &:nth-child(2) {
                        margin-top: -6px;
                        &:after {
                            top: 52%;
                            right: -16px;
                            @media screen and (max-width: 767px) {
                                top: auto !important;
                                right: auto;
                                left: 49% !important;
                                bottom: -20px;
                            }
                        }
                    }
                    &:nth-child(3) {
                        margin-top: -6px;
                        &:after {
                            top: 52%;
                            right: -16px;
                            @media screen and (max-width: 767px) {
                                top: auto !important;
                                right: auto;
                                left: 49% !important;
                                bottom: -20px;
                            }
                        }
                    }
                }
            }
            @media screen and (max-width: 1300px) {
                &__item {
                    &:first-child {
                        &:before {
                            margin-top: 1px;
                        }
                    }
                    &:last-child {
                        margin-top: 2px;
                    }
                    &--name {
                        font-size: rem(13px);
                    }
                    &--date {
                        font-size: rem(12px);
                    }
                }
            }
            @media screen and (max-width: 1200px) and (min-width: 992px) {
                width: 900px;
                &__item {
                    &:not(:last-child) {
                        &:after {
                            right: -13px !important;
                        }
                    }
                }
            }
            @media screen and (max-width: 991px) and (min-width: 768px) {
                max-width: 650px;
                margin: auto;
                &__item {
                    max-width: 120px;
                    width: 120px;
                    flex: 0 0 120px;
                    height: 120px;
                    padding: rem(55px);

                    &:before {
                        height: 100% !important;
                        background-size: contain;
                    }
                    &:not(:last-child) {
                        &:after {
                            right: -14px;
                            width: 18px;
                            height: 18px;
                            background-size: 12px, 13px;
                        }
                    }
                    &.unactive {
                        &:after {
                            background-size: 7px, 15px !important;
                        }
                    }
                    &:last-child {
                        &:before {
                            margin-top: -2px;
                        }
                    }
                }
            }
            @media screen and (max-width: 767px) {
                flex-direction: column;
                &__item {
                    width: 160px;
                    height: 160px;
                    max-width: 160px;
                    flex: 0 0 160px;
                    margin: 0 0 rem(15px) 0;
                    &--shadow {
                        display: none;
                    }
                    &:before {
                        background-size: auto !important;
                        transform: rotate(90deg) translate(-50%, 50%) !important;
                        height: 100%;
                    }
                    &:nth-child(4) {
                        &:before {
                            background-size: 98% !important;
                        }
                        &:after {
                            transform: rotate(90deg) translateY(50%) !important;
                        }
                    }
                    &.completed:last-child:before {
                        transform: translate(-50%, -50%) rotate(-90deg) !important;
                    }
                    &:after {
                        bottom: -16px;
                        top: auto;
                        left: 50%;
                        right: auto;
                        transform: translateX(-50%);
                    }
                }
            }
        }
        .ideas-tabs {
            margin-top: rem(45px);
            .nav-tabs {
                .nav-item {
                    display: inline-block;
                    flex: 1;
                    .nav-link {
                        padding-left: 0;
                        padding-right: 0;
                        text-align: center;
                    }
                }
            }
            @media screen and (max-width: 767px) {
                .nav-tabs {
                    .nav-item {
                        .nav-link {
                            font-size: rem(17px);
                        }
                    }
                }
            }
        }
        @media screen and (min-width: 1200px) and (min-width: 992px) {
            .timeline-wrapper {
                overflow: hidden;
                overflow-x: auto;
            }
        }
    }

    @media screen and (max-width: 767px) {
        padding-top: rem(112px);
        &__reviews {
            &--brief {
                /deep/.comments {
                    padding: rem(20px) 0 rem(16px) rem(20px);
                }
            }
        }
    }
}
@media screen and (min-width: 992px) {
    .accNavClose {
        /deep/.ideaDetail {
            .timeline {
                &__item {
                    &:not(:last-child) {
                        &:after {
                            right: -13px !important;
                        }
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 991px) {
    /deep/ {
        .sidenav-wrapper {
            .right-col {
                padding-left: rem(80px) !important;
            }
        }
        .content-wrapper {
            .page__head {
                min-height: 110px;
                padding-right: 0;
            }
        }
    }
}
@media screen and (max-width: 767px) {
    /deep/ {
        .page-banner {
            --h: 370px;
        }
        .content-wrapper {
            .page__head {
                min-height: 120px;
            }
        }
        .sidenav-wrapper {
            margin-top: rem(20px);
        }
    }
}

// ar style

.ar {
    .ideaDetail {
        &__reviews {
            margin-bottom: rem(40px);
            &--brief {
                /deep/ {
                    .comments {
                        padding-left: 0;
                        padding-right: 1.875rem;
                        .comment__row {
                            padding-left: rem(50px);
                            padding-right: 0;
                            &--text {
                                text-align: right;
                            }
                        }
                    }
                    .users {
                        padding-left: 0;
                        padding-right: 1.875rem;

                        &__item {
                            &:not(:last-child) {
                                padding-left: rem(30px);
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }
        /deep/ {
            .timeline {
                &__item {
                    &:not(:last-child) {
                        &::after {
                            left: -17px;
                            right: auto;
                        }
                    }
                    &.completed:last-child:before {
                        transform: translate(-50%, -50%) rotate(0deg);
                    }
                    &.unactive {
                        &:nth-child(2),
                        &:nth-child(3) {
                            &:after {
                                left: -16px;
                                right: auto;
                            }
                        }
                    }
                }
                @media screen and (max-width: 1200px) and (min-width: 992px) {
                    &__item {
                        &:not(:last-child) {
                            &:after {
                                left: -13px !important;
                                right: auto !important;
                            }
                        }
                    }
                }
                @media screen and (max-width: 991px) and (min-width: 768px) {
                    &__item {
                        &:not(:last-child) {
                            &:after {
                                left: -14px !important;
                                right: auto !important;
                            }
                        }
                    }
                }
                @media screen and (max-width: 767px) {
                    &__item {
                        &:before {
                            background-size: auto !important;
                            transform: rotate(90deg) translate(-50%, 50%) !important;
                            height: 100%;
                        }
                        &:nth-child(4) {
                            &:before {
                                background-size: 98% !important;
                            }
                            &:after {
                                transform: rotate(90deg) translateY(50%) !important;
                            }
                        }
                        &:after {
                            left: 50% !important;
                            right: auto !important;
                            transform: translateX(-50%);
                        }
                    }
                }
            }
        }
        @media screen and (max-width: 767px) {
            &__reviews {
                &--brief {
                    /deep/.comments {
                        padding: rem(20px) rem(20px) rem(16px) 0;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 992px) {
        .accNavClose {
            /deep/.ideaDetail {
                .timeline {
                    &__item {
                        &:not(:last-child) {
                            &:after {
                                left: -13px !important;
                                right: auto !important;
                            }
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 991px) {
        .content-wrapper {
            /deep/ {
                .page__head {
                    padding-left: 0;
                }
                .sidenav-wrapper {
                    .right-col {
                        padding-right: rem(80px) !important;
                        padding-left: 0 !important;
                    }
                }
            }
        }
    }
}
</style>

<style>
.ideaDetail {
    padding-top: 2rem !important;
}

@media (max-width: 1500px) {
    .ideaDetail {
        padding-top: 8rem !important;
    }
}

@media (max-width: 992px) {
    .ideaDetail {
        padding-top: 3rem !important;
    }
}
</style>
