<template>
    <div>
        <div class="ideaGallery">
            <div class="ideaGallery__nav" v-if="showNavigation == true">
                <ul class="list-inline">
                    <li>
                        <button
                            @click.prevent="slidePrev"
                            class="ideaGallery__nav--previous"
                        >
                            <img
                                loading="lazy"
                                src="@/assets/images/banner-arrow.svg"
                                alt=""
                            />
                        </button>
                    </li>
                    <li>
                        <button
                            @click.prevent="slideNext"
                            class="bideaGallery__nav--next"
                        >
                            <img
                                loading="lazy"
                                src="@/assets/images/banner-arrow.svg"
                                alt=""
                            />
                        </button>
                    </li>
                </ul>
            </div>
            <vue-slick-carousel
                v-if="medias"
                v-bind="gallerySettings"
                ref="carousel"
            >
                <div
                    class="ideaGallery__slide"
                    v-for="media in medias"
                    :key="media.id"
                >
                    <div
                        class="ideaGallery__slide--image video"
                        v-if="getFileType(media.file) == 'video'"
                    >
                        <video controls>
                            <source :src="media.file" type="video/mp4" />
                        </video>
                    </div>

                    <div class="ideaGallery__slide--image" v-else>
                        <img loading="lazy" :src="media.file" alt="" />
                    </div>
                </div>
                <!-- <div class="ideaGallery__slide video">
          <div class="ideaGallery__slide--image">
            <img src="@/assets/images/idea-detail-image-800x350.png" alt="" />
            <span class="ideaGallery__slide--playIcon">
              <svg
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                width="15px"
                height="19px"
              >
                <path
                  fill-rule="evenodd"
                  d="M14.189,8.724 L2.413,1.177 C2.065,0.966 1.632,1.002 1.306,1.002 C-0.000,1.002 0.006,2.027 0.006,2.287 L0.006,17.712 C0.006,17.931 -0.000,18.997 1.306,18.997 C1.632,18.997 2.065,19.032 2.413,18.821 L14.189,11.274 C15.156,10.689 14.989,9.999 14.989,9.999 C14.989,9.999 15.156,9.309 14.189,8.724 Z"
                />
              </svg>
            </span>
          </div>
        </div> -->
            </vue-slick-carousel>
        </div>
    </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'

export default {
    name: 'ideaGallery',
    components: {
        VueSlickCarousel,
    },
    props: {
        medias: Array,
    },
    data() {
        return {
            gallerySettings: {
                arrows: false,
                dots: true,
                centerMode: false,
                variableWidth: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                focusOnSelect: true,
            },
            showNavigation: false,
        }
    },
    created() {},
    methods: {
        slidePrev() {
            this.$refs.carousel.prev()
        },
        slideNext() {
            this.$refs.carousel.next()
        },
        getFileType(file) {
            if (
                file.split('.').pop() == 'mp4' ||
                file.split('.').pop() == 'webm'
            ) {
                return 'video'
            } else {
                return ''
            }
        },
    },
}
</script>
<style lang="scss" scoped>
.ideaGallery {
    position: relative;
    $self: &;
    &__slide {
        text-align: center;

        // width: 260px;
        &--image {
            position: relative;
            // @media screen and (min-width: 767px) {
            //     height: 357px;
            // }
            // @media screen and (max-width: 766px) and (min-width: 575px) {
            //     height: 300px;
            // }
            // @media screen and (max-width: 574px) {
            //     height: 250px;
            // }
            // @include flex(center,center);
            img {
                height: 100%;
                transform: scale(0.5);
                max-width: 100%;
                width: 100%;
                /*max-height: 340px;*/
                object-fit: cover;
            }
            video {
                height: 100%;
                width: 100%;
                object-fit: none;
                // max-width: 340px;
                max-width: 100%;
            }
        }
        &--playIcon {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            border: solid 4px #fff;
            position: absolute;
            @include center-both();
            color: #fff;
            @include flex(center, center);
            cursor: pointer;
            svg {
                width: 15px;
                height: auto;
            }
            &:hover {
                color: var(--secondary);
                border-color: var(--secondary);
            }
        }
    }
    &__nav {
        position: absolute;
        top: -40px;
        right: 0px;
        z-index: 99;
        right: calc((100% - 1240px) / 2);
        button {
            background: transparent;
            border: 0px;
            img {
                width: 28px;
            }
            @media screen and (min-width: 1025px) {
                &:hover {
                    opacity: 0.7;
                }
            }
        }
        li:not(:last-child) {
            position: relative;
            padding-right: 10px;
            margin-right: 10px;
            &:after {
                position: absolute;
                content: '';
                width: 1px;
                height: 35px;
                background: #67676a;
                @include center-vertical();
                right: 0px;
            }
        }
        &--previous {
            img {
                transform: rotate(180deg);
            }
        }
    }
    .slick-current {
        #{$self}__slide {
            width: 750px;
            &--image {
                @include flex(center, center);

                img {
                    transform: scale(1);
                    max-width: 100%;
                    border: solid 2px #009b74;
                }
            }
        }
    }
}
</style>
